import * as React from "react";
import "./assets/css/style.scss";
import LandingPage from "./pages/LandingPage";
import Logo from "./assets/images/LogoWithTagline.png";

function App() {
  return (
    <div className="App">
      {/* <LandingPage /> */}
      <div class="bgimg">
  <div class="topleft">
  <img src={Logo} alt="" />
  </div>
  <div class="middle">
    <h1 >COMING SOON</h1>

    <p>Onliest is Innovative fashion designing software</p>
  </div>
  {/* <div class="bottomleft">
    <p>Some text</p>
  </div> */}
</div>
    </div>
  );
}

export default App;
