import React from "react";
import Col from "react-bootstrap/Col";
import qualityAssured from "../../assets/images/qualityAssured.png";
import pickACardContentIcon from "../../assets/images/pickACardContentIcon.png";

const CardListItem = (props) => {
  return (
    <Col sm={6}>
      <div className="pickAStyleCardContainer">
        <div className="pickAStyleCardImage">
          <img
            src={props.character.pickImage}
            alt=""
            width={180}
            height={180}
          />
        </div>
        <div className="pickAStyleCardContent">
          <strong>{props.character.title}</strong>
          <ul>
            <li>
              <img src={qualityAssured} alt="Icon" width="20px" height="20px" />
              {props.character.quality}
            </li>
            <li>
              <img
                src={pickACardContentIcon}
                alt="Icon"
                width="15px"
                height="19px"
              />
              {props.character.content}
            </li>
          </ul>
        </div>
      </div>
    </Col>
  );
};
export default CardListItem;
