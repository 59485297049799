import React from "react";
import Navigation from "../../components/Navigation/Navigation";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ExploreMore from "../../assets/images/LandingPageHomeImage.png";

import Process01 from "../../assets/images/process-01.png";
import Process02 from "../../assets/images/process-02.png";
import Process03 from "../../assets/images/process-03.png";
import Process04 from "../../assets/images/process-04.png";
import Process05 from "../../assets/images/process-05.png";
import Process06 from "../../assets/images/process-06.png";
// import Button from "@mui/material/Button";
import CustomButton from "../../components/Buttons/Buttons";
import Navbar from "../../components/Navigation/Navbar";
import TabScrolling from "../../components/TabsScrolling/TabScrolling";
import PickAStyleCard from "../../components/PickAStyleCard/PickAStyleCard";
import CustomProductProcess from "../../components/CustomProductProcess/CustomProductProcess";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const characters = [
  {
    pickImage: "https://picsum.photos/200/300?random=1",
    title: "Purple Floral Silk",
    quality: "90% purity",
    content:
      "Fabrics are produced using 100s,120s and above count of cotton yarns for warp and weft while 4 to 6 ply cotton yarn used for extra weft either in white or dyed.",
  },
  {
    pickImage: "https://picsum.photos/200/300?random=2",
    title: "Lehariya Hot Pink",
    quality: "90% purity",
    content:
      "Fabrics are produced using 100s,120s and above count of cotton yarns for warp and weft while 4 to 6 ply cotton yarn used for extra weft either in white or dyed.",
  },
  {
    pickImage: "https://picsum.photos/200/300?random=3",
    title: "Pink Parrot Print",
    quality: "90% purity",
    content:
      "Fabrics are produced using 100s,120s and above count of cotton yarns for warp and weft while 4 to 6 ply cotton yarn used for extra weft either in white or dyed.",
  },
  {
    pickImage: "https://picsum.photos/200/300?random=4",
    title: "Red Ethnic Silk",
    quality: "90% purity",
    content:
      "Fabrics are produced using 100s,120s and above count of cotton yarns for warp and weft while 4 to 6 ply cotton yarn used for extra weft either in white or dyed.",
  },
];
const ProcessStepsJson = [
  {
    ProcessImage: Process01,
    title: "Fabrics | Colours | Designs",
    content:
      "Select from existing designs or create a new design for yourself or engage with a designer",
  },
  {
    ProcessImage: Process02,
    title: "Blouse Type, Fall & Edges closure",
    content:
      "Select your blouse style, fall stitch, and saree closure to complete your traditional Indian attire.",
  },
  {
    ProcessImage: Process03,
    title: "Weaving",
    content:
      "Committed to crafting luxurious and elegant sarees using skilled resources, advanced looms, and premium raw materials.",
  },
  {
    ProcessImage: Process04,
    title: "Blouse Stitch",
    content:
      "As an optional service, we offer to have your blouse expertly stitched using the best skilled resources and materials available.",
  },
  {
    ProcessImage: Process05,
    title: "Quality Assurance",
    content:
      "Our dedicated quality specialists ensures that your product meets the highest standards.",
  },
  {
    ProcessImage: Process06,
    title: "Unwrap the package",
    content:
      "Receive your saree with fall, blouse and get ready to make a statement",
  },
];
const tabs = [
  {
    label: "Banarasi",
    Component: (
      <div>
        <Row>
          <Col sm={8} className="pickAcardContentContainer">
            <p>
              Banaras Brocade Sarees are made of finely woven silk and decorated
              with intricate designs using zari; this ornamentation is what
              makes the sarees heavy. Their special characteristics are
              Mughal-inspired designs/elements such as intricate floral and
              foliate motifs, such as kalga and bel. Other features are gold
              work, compact weaving, figures with small details, metallic visual
              effects, “jali” (a net-like pattern) a ... Read more
            </p>
            <Row>
              {characters.map((test) => {
                return <PickAStyleCard character={test} key={test.id} />;
              })}
            </Row>
            <div className="pickACardBottonBlock">
              <CustomButton label="View All" className="primaryButton" />
            </div>
          </Col>
          <Col sm={4}>
            <div className="pickAStyleBigImage">
              <img src="https://picsum.photos/200/300?random=1" alt="" />
            </div>
          </Col>
        </Row>
      </div>
    ),
  },

  {
    label: "Bandhani",
    Component: <div>Bandhani</div>,
  },

  {
    label: "Pochampally",
    Component: <div>Pochampally</div>,
  },

  {
    label: "Tant",
    Component: <div>Tant</div>,
  },

  {
    label: "Kanjeevaram",
    Component: <div>Kanjeevaram</div>,
  },

  {
    label: "Kasovu",
    Component: <div>Kasovu</div>,
  },

  {
    label: "Jamdani",
    Component: <div>Jamdani</div>,
  },

  {
    label: "Muga silk",
    Component: <div>Muga silk</div>,
  },

  {
    label: "Paltini",
    Component: <div>Paltini</div>,
  },

  {
    label: "Phulkan",
    Component: <div>Phulkan</div>,
  },

  {
    label: "Kakamkan",
    Component: <div>Kakamkan</div>,
  },
  {
    label: "Muga silk",
    Component: <div>Muga silk</div>,
  },

  {
    label: "Paltini",
    Component: <div>Paltini</div>,
  },

  {
    label: "Phulkan",
    Component: <div>Phulkan</div>,
  },

  {
    label: "Kakamkan",
    Component: <div>Kakamkan</div>,
  },
];
const LandingPage = () => {
  return (
    <div className="wrapper">
      <div className="header">
        {/* <Navbar /> */}
        <Navigation />

        <div className="bannerContent">
          <Row>
            <Col className="createYourOwnBlock">
              <h2>Create your own</h2>
              <h1>Fashion Statement</h1>
              <p>
                Onliest helps you to create your own unique and exclusive
                fashion designs that reflect the personal style and taste of the
                individual. Our skilled team uses top-notch equipment to bring
                your designs to life, resulting in visually striking and
                comfortable fashion statements for any occasion.
              </p>
              <ul className="customizeOptionBlock">
                <li>
                  <a href="">Highlight Onliest Branding</a>
                </li>
                <li>
                  <a href="">Copyrights Program</a>
                </li>
                <li>
                  <a href="">Royalty Program</a>
                </li>
              </ul>
              <CustomButton label="DESIGN NOW" className="whiteButton" />
              <CustomButton
                label="Customize A Design"
                className="whiteButton"
              />
            </Col>
            <Col className="bannerImage">
              <img src={ExploreMore} alt="" />
            </Col>
          </Row>
        </div>
        <div className="mainContainer">
          <div className="pickAStyleBlock">
            <h5>DESIGN YOUR OUTFIT</h5>
            <h2>Pick a style and get started</h2>
            <TabScrolling tabs={tabs} />
          </div>
          {/* How to create your own iconic product */}

          <div class="row specializationContainer">
            <h1>How to create your own iconic product</h1>
            {ProcessStepsJson.map((ProcessStepsList) => {
              return (
                <CustomProductProcess
                  ProcessSteps={ProcessStepsList}
                  key={ProcessStepsList.id}
                />
              );
            })}
          </div>
          {/* No Mannequin “Look & Feel Yourself” */}
          <div className="virtualRealityBlock">
            <h1>No Mannequin "Look & Feel Yourself"</h1>
            <div className="virtualRealityContainer">
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4}></Grid>
                  <Grid item xs={6} md={4}></Grid>
                  <Grid item xs={6} md={4}></Grid>
                </Grid>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LandingPage;
